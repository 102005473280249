<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#30B868" left :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-dialog v-model="deleteDialogue" width="400">
      <v-card>
        <v-layout pa-4 wrap justify-center>
          <v-flex xs12>
            <span
              style="
                color: #000000;
                font-family: opensanssemibold;
                font-size: 18px;
              "
              >Do you want to delete this promocode ?</span
            >
          </v-flex>
          <v-flex xs6 px-2>
            <v-btn tile block depressed dark color="red" @click="deletePromocode()">
              <span style="font-family: poppinsregular; font-size: 14px">
                Yes
              </span>
            </v-btn>
          </v-flex>
          <v-flex xs6 px-2>
            <v-btn
              tile
              block
              depressed
              dark
              color="#1BD93B"
              @click="deleteDialogue = false"
            >
              <span style="font-family: poppinsregular; font-size: 14px">
                No
              </span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-dialog v-model="promoDialog" max-width="800px">
      <v-card width="800px">
        <v-layout wrap justify-center style="background-color: white">
          <v-flex px-2 xs12 sm12 md10 lg10 xl8 text-left>
            <v-layout pt-8 wrap justify-center>
              <v-flex text-center xs12 md12 lg12>
                <span
                  style="
                    color: #000000;
                    font-family: poppinsbold;
                    font-size: 22px;
                  "
                  >Add Promocode</span
                >
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex xs12 pt-8>
                <span
                  class="pl-3"
                  style="
                    color: #000000;
                    font-family: opensansbold;
                    font-size: 15px;
                    letter-spacing: 0px;
                    text-align: left;
                  "
                >
                  Promocode</span
                >
                <v-text-field
                  v-model="couponData.code"
                  placeholder="Promo Code"
                  required
                  outlined
                  dense
                ></v-text-field>
                <span
                  class="pl-3"
                  style="
                    color: #000000;
                    font-family: opensansbold;
                    font-size: 15px;
                    letter-spacing: 0px;
                    text-align: left;
                  "
                >
                  Description
                </span>
                <v-text-field
                  v-model="couponData.description"
                  placeholder="Description"
                  outlined
                  dense
                ></v-text-field>
                <span
                  class="pl-3"
                  style="
                    color: #000000;
                    font-family: opensansbold;
                    font-size: 15px;
                    letter-spacing: 0px;
                    text-align: left;
                  "
                >
                  Discount
                </span>
                <v-text-field
                  v-model="couponData.discount"
                  placeholder="Discount Percentage"
                  dense
                  required
                  outlined
                ></v-text-field>
                <span
                  class="pl-3"
                  style="
                    color: #000000;
                    font-family: opensansbold;
                    font-size: 15px;
                    letter-spacing: 0px;
                    text-align: left;
                  "
                >
                  Maximum Amount To Be Applied
                </span>
                <v-text-field
                  v-model="couponData.maxAmount"
                  placeholder="Maximum Amount To Be Applied"
                  dense
                  required
                  outlined
                ></v-text-field>
                <span
                  class="pl-3"
                  style="
                    color: #000000;
                    font-family: opensansbold;
                    font-size: 15px;
                    letter-spacing: 0px;
                    text-align: left;
                  "
                >
                  Select From Date
                </span>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="couponData.startingDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="couponData.startingDate"
                      placeholder="Select From Date"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="couponData.startingDate"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(couponData.startingDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
                <span
                  class="pl-3"
                  style="
                    color: #000000;
                    font-family: opensansbold;
                    font-size: 15px;
                    letter-spacing: 0px;
                    text-align: left;
                  "
                >
                  Select To Date
                </span>
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  :return-value.sync="couponData.endingDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="couponData.endingDate"
                      placeholder="Select To Date"
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="couponData.endingDate"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu1 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu1.save(couponData.endingDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
                <span
                  class="pl-3"
                  style="
                    color: #000000;
                    font-family: opensansbold;
                    font-size: 15px;
                    letter-spacing: 0px;
                    text-align: left;
                  "
                >
                  Select Starting Time
                </span>
                <v-menu
                  ref="menu3"
                  v-model="menu3"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="couponData.startingTime"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="couponData.startingTime"
                      placeholder="Starting Time"
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menu3"
                    v-model="couponData.startingTime"
                    full-width
                    @click:minute="$refs.menu3.save(couponData.startingTime)"
                  ></v-time-picker>
                </v-menu>
                <span
                  class="pl-3"
                  style="
                    color: #000000;
                    font-family: opensansbold;
                    font-size: 15px;
                    letter-spacing: 0px;
                    text-align: left;
                  "
                >
                  Select Ending Time
                </span>
                <v-menu
                  ref="menu4"
                  v-model="menu4"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="couponData.endingTime"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="couponData.endingTime"
                      placeholder="Ending Time"
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menu4"
                    v-model="couponData.endingTime"
                    full-width
                    @click:minute="$refs.menu4.save(couponData.endingTime)"
                  ></v-time-picker>
                </v-menu>
                <span
                  class="pl-3"
                  style="
                    color: #000000;
                    font-family: opensansbold;
                    font-size: 15px;
                    letter-spacing: 0px;
                    text-align: left;
                  "
                >
                  Maximum Order To Be Applied
                </span>
                <v-text-field
                  v-model="couponData.maximumOderTobeApplied"
                  placeholder="Maximum Order To Be Applied "
                  dense
                  required
                  type="number"
                  outlined
                ></v-text-field>
                <span
                  class="pl-3"
                  style="
                    color: #000000;
                    font-family: opensansbold;
                    font-size: 15px;
                    letter-spacing: 0px;
                    text-align: left;
                  "
                >
                  Limit Per Customer
                </span>
                <v-text-field
                  v-model="couponData.limitPerCustomer"
                  placeholder="Limit Per Customer "
                  dense
                  required
                  type="number"
                  outlined
                ></v-text-field>
                <span
                  class="pl-3"
                  style="
                    color: #000000;
                    font-family: opensansbold;
                    font-size: 15px;
                    letter-spacing: 0px;
                    text-align: left;
                  "
                >
                  Order Minimum Amount
                </span>
                <v-text-field
                  v-model="couponData.orderMinAmount"
                  placeholder=" Order Minimum Amount "
                  dense
                  required
                  type="number"
                  outlined
                ></v-text-field>
                <v-layout wrap justify-end>
                  <v-flex xs12 md6 lg6 pt-4 pb-4 pa-2 text-right>
                    <v-btn
                      tile
                      block
                      dark
                      color="black"
                      depressed
                      class="text-capitalize mb-3"
                      @click.stop="promoDialog = false"
                      :ripple="false"
                      >Close</v-btn
                    >
                  </v-flex>
                  <v-flex xs12 md6 lg6 pt-4 pb-4 text-right pa-2>
                    <v-btn
                      tile
                      block
                      dark
                      color="#30B868"
                      depressed
                      class="text-capitalize mb-3"
                      @click.prevent="addCode()"
                      :ripple="false"
                      >Submit</v-btn
                    >
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editPromoDialog" max-width="800px">
      <v-card width="800px">
        <v-layout wrap justify-center style="background-color: white">
          <v-flex px-2 xs12 sm12 md10 lg10 xl8 text-left>
            <v-layout pt-8 wrap justify-center>
              <v-flex text-center xs12 md12 lg12>
                <span
                  style="
                    color: #000000;
                    font-family: poppinsbold;
                    font-size: 22px;
                  "
                  >Edit Promocode</span
                >
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex xs12 pt-8>
                <span
                  class="pl-3"
                  style="
                    color: #000000;
                    font-family: opensansbold;
                    font-size: 15px;
                    letter-spacing: 0px;
                    text-align: left;
                  "
                >
                  Promocode</span
                >
                <v-text-field
                  v-model="promoData.code"
                  placeholder="Promo Code"
                  required
                  outlined
                  dense
                ></v-text-field>
                <span
                  class="pl-3"
                  style="
                    color: #000000;
                    font-family: opensansbold;
                    font-size: 15px;
                    letter-spacing: 0px;
                    text-align: left;
                  "
                >
                  Description
                </span>
                <v-text-field
                  v-model="promoData.description"
                  placeholder="Description"
                  outlined
                  dense
                ></v-text-field>
                <span
                  class="pl-3"
                  style="
                    color: #000000;
                    font-family: opensansbold;
                    font-size: 15px;
                    letter-spacing: 0px;
                    text-align: left;
                  "
                >
                  Discount
                </span>
                <v-text-field
                  v-model="promoData.discount"
                  placeholder="Discount Percentage"
                  dense
                  required
                  outlined
                ></v-text-field>
                <span
                  class="pl-3"
                  style="
                    color: #000000;
                    font-family: opensansbold;
                    font-size: 15px;
                    letter-spacing: 0px;
                    text-align: left;
                  "
                >
                  Maximum Amount To Be Applied
                </span>
                <v-text-field
                  v-model="promoData.maxDiscountAmount"
                  placeholder="Maximum Amount To Be Applied"
                  dense
                  required
                  outlined
                ></v-text-field>
                <span
                  class="pl-3"
                  style="
                    color: #000000;
                    font-family: opensansbold;
                    font-size: 15px;
                    letter-spacing: 0px;
                    text-align: left;
                  "
                >
                  Select From Date
                </span>
                <v-menu
                  ref="menu5"
                  v-model="menu5"
                  :close-on-content-click="false"
                  :return-value.sync="promoData.startingDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="promoData.startingDate"
                      placeholder="Select From Date"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="promoData.startingDate"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu5 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu5.save(promoData.startingDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
                <span
                  class="pl-3"
                  style="
                    color: #000000;
                    font-family: opensansbold;
                    font-size: 15px;
                    letter-spacing: 0px;
                    text-align: left;
                  "
                >
                  Select To Date
                </span>
                <v-menu
                  ref="menu6"
                  v-model="menu6"
                  :close-on-content-click="false"
                  :return-value.sync="promoData.endingDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="promoData.endingDate"
                      placeholder="Select To Date"
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="promoData.endingDate"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu6 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu6.save(promoData.endingDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
                <span
                  class="pl-3"
                  style="
                    color: #000000;
                    font-family: opensansbold;
                    font-size: 15px;
                    letter-spacing: 0px;
                    text-align: left;
                  "
                >
                  Select Starting Time
                </span>
                <v-menu
                  ref="menu7"
                  v-model="menu7"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="promoData.startingTime"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="promoData.startingTime"
                      placeholder="Starting Time"
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menu7"
                    v-model="promoData.startingTime"
                    full-width
                    @click:minute="$refs.menu7.save(promoData.startingTime)"
                  ></v-time-picker>
                </v-menu>
                <span
                  class="pl-3"
                  style="
                    color: #000000;
                    font-family: opensansbold;
                    font-size: 15px;
                    letter-spacing: 0px;
                    text-align: left;
                  "
                >
                  Select Ending Time
                </span>
                <v-menu
                  ref="menu8"
                  v-model="menu8"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="promoData.endingTime"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="promoData.endingTime"
                      placeholder="Ending Time"
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menu8"
                    v-model="promoData.endingTime"
                    full-width
                    @click:minute="$refs.menu8.save(promoData.endingTime)"
                  ></v-time-picker>
                </v-menu>
                <span
                  class="pl-3"
                  style="
                    color: #000000;
                    font-family: opensansbold;
                    font-size: 15px;
                    letter-spacing: 0px;
                    text-align: left;
                  "
                >
                  Maximum Order To Be Applied
                </span>
                <v-text-field
                  v-model="promoData.maximumOderTobeApplied"
                  placeholder="Maximum Order To Be Applied "
                  dense
                  required
                  type="number"
                  outlined
                ></v-text-field>
                <span
                  class="pl-3"
                  style="
                    color: #000000;
                    font-family: opensansbold;
                    font-size: 15px;
                    letter-spacing: 0px;
                    text-align: left;
                  "
                >
                  Limit Per Customer
                </span>
                <v-text-field
                  v-model="promoData.limitPerCustomer"
                  placeholder="Limit Per Customer "
                  dense
                  required
                  type="number"
                  outlined
                ></v-text-field>
                <span
                  class="pl-3"
                  style="
                    color: #000000;
                    font-family: opensansbold;
                    font-size: 15px;
                    letter-spacing: 0px;
                    text-align: left;
                  "
                >
                  Order Minimum Amount
                </span>
                <v-text-field
                  v-model="promoData.orderMinAmount"
                  placeholder=" Order Minimum Amount "
                  dense
                  required
                  type="number"
                  outlined
                ></v-text-field>
                <v-layout wrap justify-end>
                  <v-flex xs12 md6 lg6 pt-4 pb-4 pa-2 text-right>
                    <v-btn
                      tile
                      block
                      dark
                      color="black"
                      depressed
                      class="text-capitalize mb-3"
                      @click.stop="editPromoDialog = false"
                      :ripple="false"
                      >Close</v-btn
                    >
                  </v-flex>
                  <v-flex xs12 md6 lg6 pt-4 pb-4 text-right pa-2>
                    <v-btn
                      tile
                      block
                      dark
                      color="#30B868"
                      depressed
                      class="text-capitalize mb-3"
                      @click.prevent="editCode(promoData)"
                      :ripple="false"
                      >Submit</v-btn
                    >
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-layout wrap justify-center>
      <v-flex xs12 sm11>
        <v-layout pt-3 wrap justify-space-between>
          <v-flex xs6 sm3 md3 lg2>
            <v-layout wrap justify-center>
              <v-flex xs3 sm2 text-left align-self-center>
                <v-img
                  src="./../../../assets/greenlogo.png"
                  contain
                  height="15px"
                  alt="STYLOOP"
                ></v-img>
              </v-flex>
              <v-flex xs9 sm10 text-left pl-2 align-self-center>
                <span
                  style="
                    font-family: poppinssemibold;
                    font-size: 18px;
                    color: #1c1c1c;
                  "
                >
                  Promocode
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs6 sm3 text-right>
            <v-btn
              tile
              depressed
              outlined
              color="warning"
              small
              style="text-transform: none"
              @click="promoDialog = true"
            >
              <span
                style="
                  color: #000;
                  font-family: poppinsregular;
                  font-size: 13px;
                "
              >
                Add Promocode
              </span>
            </v-btn></v-flex
          >
        </v-layout>
        <v-layout wrap pb-4 justify-start v-if="promo.length>0">
          <v-flex
            xs12
            sm12
            pt-5
            v-for="(item, i) in promo"
            :key="i"
            text-left
            align-self-center
          >
            <v-card outlined>
              <v-layout pa-5 wrap justify-start>
                <v-flex xs10 sm10>
                  <v-layout wrap>
                    <v-flex lg12 text-left>
                      <span
                        style="
                          font-family: opensansbold;
                          font-size: 18px;
                          color: #000000;
                        "
                      >
                        {{ item.code }}
                      </span>
                    </v-flex>
                    <v-flex xs12>
                      <v-layout wrap>
                        <v-flex xs12>
                          <v-layout wrap>
                            <v-flex text-left lg12>
                              <span
                                style="
                                  color: #8d8d8d;
                                  font-family: opensanslight;
                                  font-size: 14px;
                                "
                                >Description</span
                              >
                            </v-flex>
                          </v-layout>
                          <v-layout wrap>
                            <v-flex text-left lg12>
                              <span
                                style="
                                  font-family: opensansbold;
                                  color: #000000;
                                  font-size: 16px;
                                "
                                >{{ item.description }}</span
                              >
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex>
                          <v-layout wrap>
                            <v-flex text-left lg12>
                              <span
                                style="
                                  color: #8d8d8d;
                                  font-family: opensanslight;
                                  font-size: 14px;
                                "
                                >Discount</span
                              >
                            </v-flex>
                          </v-layout>
                          <v-layout wrap>
                            <v-flex text-left lg12>
                              <span
                                style="
                                  font-family: opensansbold;
                                  color: #3bc17a;
                                  font-size: 16px;
                                "
                                >{{ item.discount }}</span
                              >
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex>
                          <v-layout wrap>
                            <v-flex text-left lg12>
                              <span
                                style="
                                  color: #8d8d8d;
                                  font-family: opensanslight;
                                  font-size: 14px;
                                "
                                >Maximum Amount To Be Applied</span
                              >
                            </v-flex>
                          </v-layout>
                          <v-layout wrap>
                            <v-flex text-left lg12>
                              <span
                                style="
                                  font-family: opensansbold;
                                  color: #3bc17a;
                                  font-size: 16px;
                                "
                                >{{ item.maxDiscountAmount }}</span
                              >
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex>
                          <v-layout wrap>
                            <v-flex text-left lg12>
                              <span
                                style="
                                  color: #8d8d8d;
                                  font-family: opensanslight;
                                  font-size: 14px;
                                "
                                >Maximum Order To Be Applied</span
                              >
                            </v-flex>
                          </v-layout>
                          <v-layout wrap>
                            <v-flex text-left lg12>
                              <span
                                style="
                                  font-family: opensansbold;
                                  color: #3bc17a;
                                  font-size: 16px;
                                "
                                >{{ item.maximumOderTobeApplied }}</span
                              >
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex>
                          <v-layout wrap>
                            <v-flex text-left lg12>
                              <span
                                style="
                                  color: #8d8d8d;
                                  font-family: opensanslight;
                                  font-size: 14px;
                                "
                                >Limit Per Customer</span
                              >
                            </v-flex>
                          </v-layout>
                          <v-layout wrap>
                            <v-flex text-left lg12>
                              <span
                                style="
                                  font-family: opensansbold;
                                  color: #3bc17a;
                                  font-size: 16px;
                                "
                                >{{ item.limitPerCustomer }}</span
                              >
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex>
                          <v-layout wrap>
                            <v-flex text-left lg12>
                              <span
                                style="
                                  color: #8d8d8d;
                                  font-family: opensanslight;
                                  font-size: 14px;
                                "
                                >Order Minimum Amount</span
                              >
                            </v-flex>
                          </v-layout>
                          <v-layout wrap>
                            <v-flex text-left lg12>
                              <span
                                style="
                                  font-family: opensansbold;
                                  color: #3bc17a;
                                  font-size: 16px;
                                "
                                >{{ item.orderMinAmount }}</span
                              >
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12>
                      <v-layout wrap justify-center>
                        <v-flex>
                          <v-layout wrap>
                            <v-flex text-left lg12>
                              <span
                                style="
                                  color: #8d8d8d;
                                  font-family: opensanslight;
                                  font-size: 14px;
                                "
                                >Start Date</span
                              >
                            </v-flex>
                          </v-layout>
                          <v-layout wrap>
                            <v-flex text-left lg12>
                              <span
                                style="
                                  font-family: opensansbold;
                                  color: #000;
                                  font-size: 16px;
                                "
                                >{{ item.startingDate }}</span
                              >
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex>
                          <v-layout wrap>
                            <v-flex text-left lg12>
                              <span
                                style="
                                  color: #8d8d8d;
                                  font-family: opensanslight;
                                  font-size: 14px;
                                "
                                >Ending Date</span
                              >
                            </v-flex>
                          </v-layout>
                          <v-layout wrap>
                            <v-flex text-left lg12>
                              <span
                                style="
                                  font-family: opensansbold;
                                  color: #000;
                                  font-size: 16px;
                                "
                                >{{ item.endingDate }}</span
                              >
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex>
                          <v-layout wrap>
                            <v-flex text-left lg12>
                              <span
                                style="
                                  color: #8d8d8d;
                                  font-family: opensanslight;
                                  font-size: 14px;
                                "
                                >Start Time</span
                              >
                            </v-flex>
                          </v-layout>
                          <v-layout wrap>
                            <v-flex text-left lg12>
                              <span
                                style="
                                  font-family: opensansbold;
                                  color: #000;
                                  font-size: 16px;
                                "
                                >{{ item.startingTime }}</span
                              >
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex>
                          <v-layout wrap>
                            <v-flex text-left lg12>
                              <span
                                style="
                                  color: #8d8d8d;
                                  font-family: opensanslight;
                                  font-size: 14px;
                                "
                                >End Time</span
                              >
                            </v-flex>
                          </v-layout>
                          <v-layout wrap>
                            <v-flex text-left lg12>
                              <span
                                style="
                                  font-family: opensansbold;
                                  color: #000;
                                  font-size: 16px;
                                "
                                >{{ item.endingTime }}</span
                              >
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs8 sm1 alin text-right>
                  <v-btn
                    tile
                    depressed
                    outlined
                    color="warning"
                    small
                    style="text-transform: none"
                    @click="(editPromoDialog = true), (promoData = item)"
                  >
                    <span
                      style="
                        color: #000;
                        font-family: poppinsregular;
                        font-size: 13px;
                      "
                    >
                      Edit
                    </span>
                  </v-btn>
                </v-flex>
                <v-flex xs2 sm1 text-right>
                    <v-icon @click="deleteDialogue = true, deleteData=item">mdi-delete</v-icon>
                  </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center pt-10 v-else>
          <v-flex xs12>
            <span
              style="
                font-family: poppinsregular;
                font-size: 25px;
                color: #000000;
              "
            >
              Oops! No Promocode Found
            </span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      timeout: 5000,
      msg: "",
      ServerError: false,
      showSnackBar: false,
      statusLoading: false,
      deleteDialogue: false,
      promoDialog: false,
      editPromoDialog: false,
      promoData: "",
      deleteData:"",
      ItemProduct: "",
      menu1: false,
      menu: false,
      menu3: false,
      menu4: false,
      menu5: false,
      menu6: false,
      menu7: false,
      menu8: false,
      promo: "",
      couponData: {
        discount: null,
        code: null,
        description: "",
        startingDate: new Date().toISOString().substr(0, 10),
        endingDate: new Date().toISOString().substr(0, 10),
        startingTime: "",
        endingTime: "",
        maxAmount: "",
        maximumOderTobeApplied: "",
        limitPerCustomer: "",
        orderMinAmount: "",
      },
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/promoCode/list/seller",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.promo = response.data.data;
          }
        })
        .catch((err) => {
          // this.ServerError = true;
          console.log(err);
        });
    },
    addCode() {
      axios({
        url: "/PromoCode/add/seller/common",
        method: "POST",
        data: this.couponData,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.getData();
            this.promoDialog = false;
            this.msg = "Added Sucessfully";
            this.showSnackBar = true;
            this.couponData = "";
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editCode(item) {
      axios({
        url: "/promoCode/edit/seller",
        method: "POST",
        data:{
            discount: this.promoData.discount,
            code: this.promoData.code,
            description: this.promoData.description,
            startingDate: this.promoData.startingDate,
            endingDate: this.promoData.endingDate,
            startingTime: this.promoData.startingTime,
            endingTime: this.promoData.endingTime,
            maximumOderTobeApplied: this.promoData.maximumOderTobeApplied,
            maxDiscountAmount: this.promoData.maxDiscountAmount,
            limitPerCustomer: this.promoData.limitPerCustomer,
            orderMinAmount: this.promoData.orderMinAmount,
            id:item._id
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.getData();
            this.msg = "Edited Sucessfully";
            this.showSnackBar = true;
            this.editPromoDialog=false
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deletePromocode() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/promoCode/delete",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.deleteData._id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.deleteDialogue = false;
            this.getData()
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      day = day < 10 ? "0" + day : day;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
  },
};
</script>